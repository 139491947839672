import { useQuery } from '@tanstack/react-query';

import { hubGqlClient } from '~/libs/gql';
import { browserStorage } from '~/libs/localforage';
import { setState, useOrderId, useOrderRef } from '~/store';
import { graphql } from '~/types/__generated/gql';
import { GetOrderByIdQuery, GetOrderByRefQuery } from '~/types/__generated/gql/graphql';
import { State } from '~/types/store';

const queryGetOrderByRef = /* GraphQL */ `
	query GetOrderByRef($ref: String!) {
		getOrderByRef(ref: $ref) {
			_id
			created_at
			updated_at
			index
			prepared_at
			processed_at
			paid_at
			cancelled_at
			prepared_by
			processed_by
			paid_by
			cancelled_by
			changelogs {
				created_at
				updated_at
				user
				identifier
				old_value
				new_value
				type
			}
			user
			customer
			venue
			status
			is_paid
			payway_ref
			payway_verification_data
			staff
			original_orders
			cancelled_reason
			source
			device
			prep_time
			currencies_configs
			location
			current_location
			items {
				_id
				category
				item
				title
				original_price
				minimum_required_price
				original_price_addons {
					vat {
						percentage
						amount
					}
					service_charge {
						percentage
						amount
					}
				}
				unit_price
				listed_price
				quantity
				extra_quantity
				serving_quantity
				comments
				cancelled_reason
				recipe {
					raw_material
					unit_of_measurement
					consumed_unit_of_measurement
					consumed_quantity
				}
				options {
					_id
					option
					uid
					title
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					listed_price
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					is_hide_from_receipt
				}
				type
				delivered_count
				status
				prep_time
				printer_tag
				subtotal
				subtotal_addons {
					offer {
						metadata
						amount
					}
					discount {
						is_divided
						type
						value
						amount
					}
					vat {
						is_included
						percentage
						amount
					}
					service_charge {
						is_included
						percentage
						amount
					}
					adjustment {
						amount
					}
				}
				net_amount
				gross_amount
				cancelled_amount
				no_vat
				no_service_charge
			}
			cancelled_items {
				_id
				category
				item
				title
				original_price
				minimum_required_price
				original_price_addons {
					vat {
						percentage
						amount
					}
					service_charge {
						percentage
						amount
					}
				}
				unit_price
				listed_price
				quantity
				extra_quantity
				serving_quantity
				comments
				cancelled_reason
				recipe {
					raw_material
					unit_of_measurement
					consumed_unit_of_measurement
					consumed_quantity
				}
				options {
					_id
					option
					uid
					title
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					listed_price
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					is_hide_from_receipt
				}
				type
				delivered_count
				status
				prep_time
				printer_tag
				subtotal
				subtotal_addons {
					offer {
						metadata
						amount
					}
					discount {
						is_divided
						type
						value
						amount
					}
					vat {
						is_included
						percentage
						amount
					}
					service_charge {
						is_included
						percentage
						amount
					}
					adjustment {
						amount
					}
				}
				net_amount
				gross_amount
				cancelled_amount
				no_vat
				no_service_charge
			}
			max_prepare_time
			items_count
			items_count_by_types
			prepped_count
			subtotal
			offer_amount
			discount_amount
			net_amount
			vat_amount
			service_charge_amount
			adjustment_amount
			grand_total
			gross_amount
			cancelled_amount
			receipt
			is_needing_prep_time_confirmation
			note
			is_cancelled
			is_needing_payment_confirmation

			_current_location {
				_id
				name
				type
				hash
			}
			_customer {
				_id
				first_name
				last_name
			}
			_receipt {
				_id
				payment_types {
					amount
					payment_type
					code
				}
			}
		}
	}
`;

const queryGetOrderById = /* GraphQL */ `
	query GetOrderById($id: ObjectID!) {
		getOrderById(id: $id) {
			_id
			created_at
			updated_at
			index
			prepared_at
			processed_at
			paid_at
			cancelled_at
			prepared_by
			processed_by
			paid_by
			cancelled_by
			changelogs {
				created_at
				updated_at
				user
				identifier
				old_value
				new_value
				type
			}
			user
			customer
			venue
			status
			is_paid
			payway_ref
			payway_verification_data
			staff
			original_orders
			cancelled_reason
			source
			device
			prep_time
			currencies_configs
			location
			current_location
			items {
				_id
				category
				item
				title
				original_price
				minimum_required_price
				original_price_addons {
					vat {
						percentage
						amount
					}
					service_charge {
						percentage
						amount
					}
				}
				unit_price
				listed_price
				quantity
				extra_quantity
				serving_quantity
				comments
				cancelled_reason
				recipe {
					raw_material
					unit_of_measurement
					consumed_unit_of_measurement
					consumed_quantity
				}
				options {
					_id
					option
					uid
					title
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					listed_price
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					is_hide_from_receipt
				}
				type
				delivered_count
				status
				prep_time
				printer_tag
				subtotal
				subtotal_addons {
					offer {
						metadata
						amount
					}
					discount {
						is_divided
						type
						value
						amount
					}
					vat {
						is_included
						percentage
						amount
					}
					service_charge {
						is_included
						percentage
						amount
					}
					adjustment {
						amount
					}
				}
				net_amount
				gross_amount
				cancelled_amount
				no_vat
				no_service_charge
			}
			cancelled_items {
				_id
				category
				item
				title
				original_price
				minimum_required_price
				original_price_addons {
					vat {
						percentage
						amount
					}
					service_charge {
						percentage
						amount
					}
				}
				unit_price
				listed_price
				quantity
				extra_quantity
				serving_quantity
				comments
				cancelled_reason
				recipe {
					raw_material
					unit_of_measurement
					consumed_unit_of_measurement
					consumed_quantity
				}
				options {
					_id
					option
					uid
					title
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					listed_price
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					is_hide_from_receipt
				}
				type
				delivered_count
				status
				prep_time
				printer_tag
				subtotal
				subtotal_addons {
					offer {
						metadata
						amount
					}
					discount {
						is_divided
						type
						value
						amount
					}
					vat {
						is_included
						percentage
						amount
					}
					service_charge {
						is_included
						percentage
						amount
					}
					adjustment {
						amount
					}
				}
				net_amount
				gross_amount
				cancelled_amount
				no_vat
				no_service_charge
			}
			max_prepare_time
			items_count
			items_count_by_types
			prepped_count
			subtotal
			offer_amount
			discount_amount
			net_amount
			vat_amount
			service_charge_amount
			adjustment_amount
			grand_total
			gross_amount
			cancelled_amount
			receipt
			is_needing_prep_time_confirmation
			note
			is_cancelled
			is_bill_printed
			is_needing_payment_confirmation

			_current_location {
				_id
				name
				type
				hash
			}
			_customer {
				_id
				first_name
				last_name
			}
			_receipt {
				_id
				payment_types {
					amount
					payment_type
					code
				}
			}
		}
	}
`;

export type GetOrderResults = GetOrderByRefQuery['getOrderByRef'] | GetOrderByIdQuery['getOrderById'];

export const getOrderQueryKey = (key) => ['order', key];

export const useGetOrder = (enabled?) => {
	const orderId = useOrderId();
	const orderRef = useOrderRef();

	return useQuery({
		enabled: enabled ?? (!!orderId || !!orderRef),
		queryKey: getOrderQueryKey(orderId || orderRef),
		queryFn: () => {
			if (orderRef)
				return hubGqlClient
					.request(graphql(queryGetOrderByRef), { ref: orderRef })
					.then((res) => res.getOrderByRef);

			if (orderId)
				return hubGqlClient
					.request(graphql(queryGetOrderById), { id: orderId })
					.then((res) => res.getOrderById);

			return Promise.reject('No order to fetch');
		},
		onSuccess: async (data) => {
			if (!data) return;

			const newState = {
				venueId: data.venue,
				locationId: data.location,
			} as State;

			if (data.device) {
				newState.deviceId = data.device as string;
				browserStorage.deviceId.set(data.device);
			}

			setState(newState);
		},
	});
};

export const useGetOrderCache = () => {
	return useGetOrder(false);
};
